const config = {
    development: {
        BASE_URL: 'http://127.0.0.1:5000/v1/',
        RECAPTCHA_SITE_KEY: '6Le-ir8eAAAAAA3P4Uh0dnLei7MHln7iMtSYk0kc',
        ENV: 'development'
    },
    sand: {
        BASE_URL: 'https://sand-anchor.ixn.tech/v2/',
        RECAPTCHA_SITE_KEY: '6LfDjL8eAAAAAATXg11qxFnXeOBE0T6rKQmJjQnF',
        ENV: 'sand'
    },
    qa: {
        BASE_URL: 'https://qa-anchor.ixn.tech/v2/',
        RECAPTCHA_SITE_KEY: '6LfDjL8eAAAAAATXg11qxFnXeOBE0T6rKQmJjQnF',
        ENV: 'qa'
    },
    production: {
        BASE_URL: 'https://prod-anchor.thelifedx.com/v1/',
        RECAPTCHA_SITE_KEY: '6LfOjL8eAAAAAM_H4-iMnpvAt-VZD8WVrKSPi00E',
        ENV: 'production'
    },
    azure_sand: {
        BASE_URL: 'https://sand-anchor.ixn.io/v2/',
        RECAPTCHA_SITE_KEY: '6LfDjL8eAAAAAATXg11qxFnXeOBE0T6rKQmJjQnF',
        ENV: 'azure_sand'
    },
    azure_production: {
        BASE_URL: 'https://anchor.ixn.io/v2/',
        RECAPTCHA_SITE_KEY: '6LfOjL8eAAAAAM_H4-iMnpvAt-VZD8WVrKSPi00E',
        ENV: 'azure_production'
    }
};
